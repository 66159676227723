import { PreambleConfig } from '../Preamble'

type ProductTourStep = {
  preamble: PreambleConfig
  followupQuestions: Array<{ id: number; question: string; answer: string }>
}

export const productTour: ProductTourStep[] = [
  {
    preamble: {
      title: 'Level up your career with in-depth courses'
    },
    followupQuestions: [
      {
        id: 1,
        question: 'What makes Reforge courses unique?',
        answer:
          'Reforge courses are unique due to their richness of in-depth yet actionable insights, expert-led sessions, and a community-driven approach. They are designed to provide practical knowledge that can be directly applied to real-world challenges, with content created by executives who are leaders in their fields.'
      },
      {
        id: 2,
        question: 'What’s the difference between on-demand and live courses on Reforge?',
        answer:
          'Our live courses have a more intense time frame (2-6 weeks depending on the live course) and offer the opportunity to attend events and interact with others participating in the same course. You will have the opportunity to interact with your course host and experts in the field throughout the live experience. [Find an upcoming live course you’d like to join here](/courses?tab=live).\n\nAll Reforge courses are available on-demand to paying members, meaning you have access to the courses written material to work through at your own pace.'
      },
      {
        id: 3,
        question: 'How do I enroll in a live course?',
        answer:
          'Enrolling is easy! [Find an upcoming live course you’d like to join here](/courses?tab=live). Once you’ve found a course you’re interested in, click enroll from the course’s landing page.\n\nIf you have an available Live Course Pass, there’s no additional cost to enroll!\n\nIf you do not have an available Live Course Pass, you can click follow the enrollment steps and when prompted, make the purchase to confirm your enrollment.'
      }
    ]
  },
  {
    preamble: {
      title: 'Write drafts & get strategic advice applied directly to you'
    },
    followupQuestions: [
      {
        id: 4,
        question: 'How is Reforge AI different?',
        answer:
          "**Personalization:** Reforge AI tailors its responses based on user-provided personalization information, such as role, industry, and specific challenges. This ensures that the guidance is relevant and directly applicable to the user's professional context.\n\n**Contextual Expertise:** It draws on a rich repository of Reforge content, including courses, guides, lessons, and artifacts, to provide precise and actionable advice. This content is crafted by industry experts, ensuring high-quality insights.\n\n**Actionability**: Reforge AI employs a structured framework for interaction, focusing on providing concise, actionable answers, personalized examples, brainstorming ideas, and insights tailored to the user's needs.\n\n**Transparency:** It maintains a strict citation format to ensure clarity and traceability of information, enhancing the reliability of the guidance provided."
      },
      {
        id: 5,
        question: 'What can I draft with Reforge?',
        answer:
          'With Drafts, you can turn what you learn on Reforge into your own real work in minutes, personalized to your unique context and rooted in Reforge’s high quality expertise.\n\nRemix select Reforge artifacts and templates to create everything from product strategies, vision narratives, and roadmaps, to PRDs and GTM launch plans.'
      },
      {
        id: 6,
        question: 'How is my data being used?',
        answer:
          "Protecting our member's data is a top priority at Reforge. You get to decide what you share about yourself and we'll never sell that information to third parties. We use OpenAI’s GPT-4 model to generate responses. Reforge’s agreement with OpenAI provides that data is not to be used for model training. Data inputted into Reforge AI features is sent to OpenAI for processing and generating AI outputs.\n\nVisit the [OpenAI Security Portal](https://trust.openai.com/) to [learn more about data retention and security practices](https://trust.openai.com/?itemUid=af8eb7bc-d4b7-4e13-a4b7-6f05e2744c22&source=click).\n\nFor more information or if you have specific security concerns, please reach out to our team at [**hello@reforge.com**](mailto:hello@reforge.com)"
      }
    ]
  },
  {
    preamble: {
      title: 'Find inspiration in the real work of others'
    },
    followupQuestions: [
      {
        id: 7,
        question: 'How can I find content relevant to me?',
        answer:
          'Reforge has 1000s of pieces of content across Product, Growth, Marketing, and so much more. There are a few great ways to find content that’s relevant to you:\n\n- On your **homepage**, we’ll recommend courses and content based on what you’ve told us you’re interested in and update those recommendations regularly based on your latest activity. The more you read, the more personalized your recommendations will become!\n\n- Visit **Explore** to browse by popular topics and sub-topics, like growth, product management, career development, and so much more.\n\n- Use **Search** to quickly find content on specific topics. You can even go beyond keywords to ask questions to find what you’re looking for.\n\n- You can also turn to **Reforge’s AI chat** to get even more tailored advice and recommendations.'
      },
      {
        id: 8,
        question: 'Why should I trust Reforge content?',
        answer:
          'Reforge content is crafted by industry experts and operators who have extensive experience in their respective fields. Here are a few reasons why you can trust Reforge content:\n\n1. **Expert Contributors:** Courses and guides are developed by leaders and experts from top companies, ensuring that the content is both relevant and actionable.\n\n2. **Practical Focus:** The content is designed to be directly applicable to real-world scenarios, helping you implement strategies and frameworks effectively in your work.\n\n3. **Community-Driven Insights:** Reforge incorporates insights and examples from a community of professionals, providing diverse perspectives and practical examples.\n\n4. **Continuous Updates:** The content is regularly updated to reflect the latest trends and best practices in the industry, ensuring you have access to current and reliable information.'
      },
      {
        id: 9,
        question: 'What are artifacts and how can I use them?',
        answer:
          'Artifacts are real examples of work and can be anything that you produce as part of your work. Examples: a word doc, strategy presentation, spreadsheet, diagram, marketing campaign, dashboard, process, analysis, etc. What makes artifact unique are the annotations by the authors themselves, showing why they created the artifact, what went into it, and much more.\n\nExplore Artifacts: [www.reforge.com/artifacts](www.reforge.com/artifacts)'
      }
    ]
  },
  {
    preamble: {
      title: 'Expert feedback right in your work tools'
    },
    followupQuestions: [
      {
        id: 10,
        question: 'Why should I use the Reforge extension?',
        answer:
          'Make smarter decisions with expertise from leading tech experts, all right in your existing workflows. The Reforge extension brings expert feedback into the tools you already use everyday, including Notion, Google Docs, Confluence, Coda, Microsoft Word, JIRA, and Linear.\n\nLearn more at [www.reforge.com/extension](/extension)'
      },
      {
        id: 11,
        question: 'How do I install the extension?',
        answer:
          'Visit [www.reforge.com/extension](/extension) to install the Reforge extension to your web browser. It takes less than 5 minutes to get up and running!'
      },
      {
        id: 12,
        question: 'How is my data being used?',
        answer:
          'Your data remains completely private because we don’t store or use it for training —ensuring that your sensitive information is secure and never retained beyond your immediate use.\n\n1. No storing of your data\n\n2. No training on your data\n\n3. Only reads what you open'
      }
    ]
  }
]
