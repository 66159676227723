import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'
import { useCallback, useState } from 'react'

import LeaveCheckoutConfirmationModal from 'domains/Subscription/LeaveCheckoutConfirmationModal'
import { ExternalValues, StandaloneBillingInfoForm } from 'domains/User/BillingInfoForm'
import {
  IS_ADDRESS_EDITING_ERROR_MESSAGE,
  NO_BILLING_ADDRESS_ERROR_MESSAGE
} from 'domains/User/BillingInfoForm/utils'
import ReforgeCardElement from 'domains/User/ReforgeCardElement'

import Button from 'components/Button'
import { Modal, ModalContent, ModalTitle } from 'components/Modal'
import { StripeElements } from 'components/StripeElements'
import StyledCheckbox from 'components/StyledCheckbox'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { BillingAddressFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'
import useModalTracking from 'hooks/useModalTracking'

import { getPageFromPath } from 'utils/location'
import { sendData } from 'utils/sendData'
import { trackCtaClicked } from 'utils/tracking/analytics'

import {
  DefaultPaymentMethodPayload,
  ReactivateSubscriptionPayload
} from 'typings/payloads'

export interface AddPaymentAndRenewModalProps {
  setOpenConfirmationModal: (arg0: boolean) => void
  getLastFour: () => void
  isOpen: boolean
  isPaidMonthly: boolean
  isSmallScreen: boolean
  setIsOpen: (arg0: boolean) => void
  setShouldInitiateReactivationFlow: (arg0: boolean) => void
  subscriptionId: string
}

const AddPaymentAndRenewModalContainer = ({
  setOpenConfirmationModal,
  getLastFour,
  isOpen,
  isPaidMonthly,
  isSmallScreen,
  setIsOpen,
  setShouldInitiateReactivationFlow,
  subscriptionId
}: AddPaymentAndRenewModalProps) => {
  return (
    <AddPaymentAndRenewModal
      setOpenConfirmationModal={setOpenConfirmationModal}
      isPaidMonthly={isPaidMonthly}
      isOpen={isOpen}
      isSmallScreen={isSmallScreen}
      setIsOpen={setIsOpen}
      getLastFour={getLastFour}
      setShouldInitiateReactivationFlow={setShouldInitiateReactivationFlow}
      stripeCardElement={CardElement}
      subscriptionId={subscriptionId}
    />
  )
}

const AddPaymentAndRenewModal = ({
  setOpenConfirmationModal,
  getLastFour,
  isOpen,
  isPaidMonthly,
  isSmallScreen,
  setIsOpen,
  setShouldInitiateReactivationFlow,
  stripeCardElement,
  subscriptionId
}: {
  setOpenConfirmationModal: (arg0: boolean) => void
  getLastFour: () => void
  isOpen: boolean
  isPaidMonthly: boolean
  isSmallScreen: boolean
  setIsOpen: (arg0: boolean) => void
  setShouldInitiateReactivationFlow: (arg0: boolean) => void
  stripeCardElement: typeof CardElement
  subscriptionId: string
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [stripeReady, setStripeReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] = useState(true)
  const [paymentMethodAddedToStripe, setPaymentMethodAddedToStripe] = useState(false)
  const [openConfirmLeaveCheckoutModal, setOpenConfirmLeaveCheckoutModal] =
    useState(false)
  const [isAddressEditing, setIsAddressEditing] = useState(false)
  const [billingAddress, setBillingAddress] = useState<BillingAddressFragment | null>(
    null
  )

  useModalTracking(isOpen, {
    modal_group: 'reactivation_flow',
    modal_name: 'add payment and renew'
  })

  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const ctaDisabled =
    errorMessage ||
    !stripeReady ||
    loading ||
    paymentMethodAddedToStripe ||
    !billingAddress

  const handleClose = () => {
    if (elements && CardElement) {
      elements?.getElement(CardElement)?.clear()
    }
    setShouldInitiateReactivationFlow(false)
    setIsOpen(false)
    setOpenConfirmLeaveCheckoutModal(true)
  }

  const submitPaymentMethod = () => {
    if (!stripe || !elements) {
      return
    }

    setLoading(true)

    // create a Stripe::SetupIntent from the backend and return the client secret
    sendData(
      '/api/v1/stripe_payments/setup_intents',
      'POST',
      {},
      (errorMessage: any, responseText: any) => {
        if (errorMessage) {
          setLoading(false)
          setErrorMessage(errorMessage.errors)
          return
        }
        if (responseText) {
          // This is where the card is set up for future payments and associated with the user.
          stripe
            .confirmCardSetup(responseText.client_secret, {
              payment_method: {
                card: elements.getElement(stripeCardElement) as StripeCardElement
              }
            })
            .then((result: any) => {
              if (result.error) {
                setLoading(false)
                setErrorMessage(result.error.message)
              } else {
                if (isDefaultPaymentMethod) {
                  patchDefaultPaymentMethod(result.setupIntent.payment_method)
                } else {
                  setPaymentMethodAddedToStripe(true)
                  reactivateSubscription()
                }
              }
            })
            .catch(() => {
              setLoading(false)
            })
        }
      }
    )
  }

  const reactivateSubscription = () => {
    setErrorMessage(null)
    const data: ReactivateSubscriptionPayload = {
      subscriptions: {
        revert_cancel: true
      }
    }

    sendData(`/api/v1/subscriptions/${subscriptionId}`, 'PATCH', data, (error: any) => {
      setLoading(false)
      if (error) {
        setErrorMessage(
          'Sorry, there was a problem restarting your subscription. Please try again or contact us at hello@reforge.com'
        )
      } else {
        getLastFour()
        setIsOpen(false)
        setOpenConfirmationModal(true)
      }
    })
  }

  const patchDefaultPaymentMethod = (paymentMethodId: string | number) => {
    const data: DefaultPaymentMethodPayload = { default_payment_method: true }
    sendData(
      `/api/v1/stripe_payments/payment_methods/${paymentMethodId}`,
      'PATCH',
      data,
      (errorMessage: any) => {
        if (errorMessage) {
          setLoading(false)
          setErrorMessage(errorMessage.errors)
        } else {
          setPaymentMethodAddedToStripe(true)
          reactivateSubscription()
        }
      }
    )
  }

  const handleCtaClick = () => {
    if (!validateAddressFormIsComplete()) {
      return
    }

    submitPaymentMethod()
    trackCtaClicked({
      cta_location: getPageFromPath(),
      cta_type: 'button',
      text: 'Add Card and Renew'
    })
  }

  const getMainText = () => {
    return isPaidMonthly
      ? `Upon adding your payment method, your membership will renew for the next
      year and you’ll be charged monthly for the next 12 months.`
      : 'Upon adding your payment method, your membership will renew for the next year.'
  }

  const validateAddressFormIsComplete = () => {
    if (!billingAddress) {
      setErrorMessage(NO_BILLING_ADDRESS_ERROR_MESSAGE)
      return false
    }
    if (isAddressEditing) {
      setErrorMessage(IS_ADDRESS_EDITING_ERROR_MESSAGE)
      return false
    }
    setErrorMessage(null)
    return true
  }

  const handleSavedBillingAddress = ({
    values,
    isComplete
  }: {
    values: ExternalValues
    isComplete: boolean
  }) => {
    if (isComplete && values.billingAddress) {
      setBillingAddress(values.billingAddress)
      setErrorMessage(null)
    }
  }

  const handleEditStateChange = useCallback((editState) => {
    setIsAddressEditing(editState)
    setErrorMessage(null)
  }, [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        className="max-w-2xl"
        scrollContent={false}
      >
        <div className="flex flex-col">
          <ModalTitle className="px-8 md:px-11">
            {isMobile
              ? 'Add Payment Method'
              : 'Add Payment Method and Renew Subscription'}
          </ModalTitle>
          <ModalContent className="py-10" scrollContent={false}>
            <p>{getMainText()}</p>

            <StripeElements>
              <StandaloneBillingInfoForm
                trackingLocation="add-payment-and-renew-modal"
                onSave={(values, isComplete) =>
                  handleSavedBillingAddress({ values, isComplete })
                }
                onLoad={(values, isComplete) =>
                  handleSavedBillingAddress({ values, isComplete })
                }
                onEditStateChange={handleEditStateChange}
              />
            </StripeElements>

            <div className="mt-8">
              <ReforgeCardElement
                onChange={(e) => {
                  if (e.error) {
                    setErrorMessage(e.error.message)
                    setStripeReady(false)
                  } else {
                    setErrorMessage(null)
                    setStripeReady(e.complete)
                  }
                }}
                errorMessage={errorMessage}
              />
              <label
                className="mb-2.5 flex flex-auto items-center"
                onClick={() => {
                  setIsDefaultPaymentMethod(!isDefaultPaymentMethod)
                }}
              >
                <StyledCheckbox checked={isDefaultPaymentMethod} />
                Set as primary payment method
              </label>
            </div>
            <div className={`flex ${isMobile ? 'justify-center' : 'justify-end'}`}>
              <Button
                onClick={handleClose}
                size={isMobile ? 'x-small' : 'medium'}
                variant="text-only"
                className={isMobile ? 'mr-5 border-2 border-rb-gray-500' : ''}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCtaClick}
                size={isMobile ? 'x-small' : 'medium'}
                shape="rounded"
                disabled={!!ctaDisabled}
                className={
                  ctaDisabled
                    ? 'border-rb-gray-100 bg-rb-gray-100 hover:cursor-default hover:border-rb-gray-100 hover:bg-rb-gray-100'
                    : 'border-rb-blue-100 bg-rb-blue-100'
                }
              >
                {loading ? 'Saving...' : 'Add Card and Renew'}
              </Button>
            </div>
          </ModalContent>
        </div>
      </Modal>
      <LeaveCheckoutConfirmationModal
        isOpen={openConfirmLeaveCheckoutModal}
        isSmallScreen={isSmallScreen}
        setIsOpen={setOpenConfirmLeaveCheckoutModal}
        setOpenPreviousModal={setIsOpen}
        setInitiateFlow={setShouldInitiateReactivationFlow}
      />
    </>
  )
}

export default AddPaymentAndRenewModalContainer
