import { FC } from 'react'

import { cn } from 'utils/tailwind'

import { ReactComponent as ArrowLeft } from 'images/arrow-left.svg'
import { ReactComponent as ArrowRight } from 'images/arrow-right.svg'

interface ProgressInputProps {
  currentStep: number
  totalSteps: number
  onNext: () => void
  onPrevious: () => void
  onFinish: () => void
}

export const ProgressInput: FC<ProgressInputProps> = ({
  currentStep,
  totalSteps,
  onNext,
  onPrevious,
  onFinish
}) => {
  return (
    <div className="flex flex-col w-full shrink-0 flex-grow overflow-hidden border border-rb-gray-100 bg-white shadow-lg rounded-xl rounded-t-2xl">
      <div className="flex items-center justify-center gap-2 p-4 border-b border-rb-gray-100">
        {Array.from({ length: totalSteps }).map((_, i) => (
          <div
            key={i}
            className={cn('w-2 h-2 rounded-full', {
              'bg-rb-gray-100': currentStep !== i,
              'bg-rb-teal-150': currentStep === i
            })}
          />
        ))}
      </div>
      <div className="flex items-center justify-between p-3">
        <button
          onClick={onPrevious}
          className={cn(
            'flex gap-2 p-1 items-center justify-center',
            currentStep === 0 && 'opacity-30 pointer-events-none'
          )}
        >
          <ArrowLeft width={16} />
          Back
        </button>
        {currentStep < totalSteps - 1 && (
          <button
            onClick={onNext}
            className={cn('flex gap-2 p-1 items-center justify-center')}
          >
            Next
            <ArrowRight width={16} />
          </button>
        )}
        {currentStep >= totalSteps - 1 && (
          <button
            onClick={onFinish}
            className={cn('flex gap-2 p-1 items-center justify-center')}
          >
            Finish
            <ArrowRight width={16} />
          </button>
        )}
      </div>
    </div>
  )
}
