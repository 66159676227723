import { useEffect } from 'react'

import { useTrackServerEventMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { getAnonymousId } from 'utils/tracking/segment'

const SetupFeatureFlagTracking = () => {
  const { isLoggedIn } = useCurrentUser()
  const [trackServerEvent] = useTrackServerEventMutation()

  useEffect(() => {
    // track feature flag status for logged out users
    trackServerEvent({
      variables: {
        input: {
          event: 'Conversion Page Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            path: window.location.pathname,
            url: window.location.href,
            logged_in: isLoggedIn
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default SetupFeatureFlagTracking
