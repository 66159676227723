import { FC } from 'react'

export const ScrollableChatContainer: FC = ({ children }) => {
  return (
    <div
      id="ai-chat-global-messages"
      className="hide-scrollbar flex flex-col gap-6 overflow-y-auto"
    >
      {children}
    </div>
  )
}
