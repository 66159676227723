import { useState } from 'react'

import Button from 'components/Button'
import ArrowUpRight from 'components/icons/ArrowUpRight'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'

import { UpcomingSeasonEnrollmentFieldsFragment } from 'gql'

import { formatInTimezone } from 'utils/date'
import { getPageFromPath } from 'utils/location'
import { track, trackCtaClicked } from 'utils/tracking/analytics'

import EnrollmentBannerImg from 'images/enrollment_banner_img.png'

export interface EnrollmentBannerProps {
  userId: string
  userTimezone?: string | null
  enrollmentSeason: UpcomingSeasonEnrollmentFieldsFragment
}

export default function EnrollmentBanner({
  userId,
  userTimezone,
  enrollmentSeason
}: EnrollmentBannerProps) {
  const [hasEnrollmentBannerShownEventFired, setHasEnrollmentBannerShownEventFired] =
    useState(false)

  const fireEnrollmentBannerShownEvent = () => {
    if (!hasEnrollmentBannerShownEventFired) {
      // @ts-ignore - 'Enrollment Banner Displayed' event is not defined in Segment JIRA#REF-5159
      track('Enrollment Banner Displayed', {
        location: getPageFromPath(),
        user_id: userId,
        season_name: `${enrollmentSeason.year}-${enrollmentSeason.name}`
      })

      setHasEnrollmentBannerShownEventFired(true)
    }
  }

  const getSeasonStartDate = () => {
    const dateFormat = 'MMMM do, yyyy'
    return formatInTimezone(enrollmentSeason.startsAt, userTimezone, dateFormat)
  }

  const onEnrollClick = () => {
    const COURSES_MARKETING_PAGE_URL = 'https://www.reforge.com/courses#courses'
    trackCtaClicked({
      cta_location: 'course_enrollment_banner',
      cta_type: 'button',
      text: 'Explore courses',
      destination: COURSES_MARKETING_PAGE_URL
    })

    window.open(COURSES_MARKETING_PAGE_URL, '_blank')
  }

  fireEnrollmentBannerShownEvent()

  return (
    <div
      className="mx-4 my-2 flex items-center justify-between border border-rb-gray-250 bg-[#F5F7FF] bg-cover bg-no-repeat py-3 px-5 sm:py-5 sm:pr-14 sm:pl-7.5"
      style={{
        backgroundImage: `url(${EnrollmentBannerImg})`,
        backgroundPosition: 'right'
      }}
    >
      <div className="flex flex-col justify-center">
        <RfHeader2 className="!font-normal">Enroll in a Live Course</RfHeader2>
        <div className="text-sm font-light text-rb-gray-300">
          Live courses begin: {getSeasonStartDate()}
        </div>
      </div>

      <Button onClick={onEnrollClick} variant="fill" className="rounded" size="x-small">
        Explore courses{' '}
        <ArrowUpRight className="ml-3 hover:fill-current hover:text-rb-white" />
      </Button>
    </div>
  )
}
