import { FC, useMemo } from 'react'

import { cn } from 'utils/tailwind'

import reforgeSymbol from 'images/reforge-logo-symbol.svg'

import { ModeName } from '../GlobalChatProvider'
import { ASSISTANT_MESSAGE_BUBBLE_CLASSES } from './helpers'

const defaultPreambleTitles: string[] = [
  'What can we build together today?',
  'How can I help you do the best work of your career today?',
  'Ready to tackle a challenge? How can I assist?',
  'How can we spark change together today?',
  'What can I help you achieve today?',
  'How can I support your next big move?',
  "Need a hand? Let's get started.",
  "What's on your mind? Let's make it happen.",
  'What growth can I help you unlock today?'
]

export type PreambleConfig = {
  title: string
  description?: string
}

interface PreambleChatProps {
  mode: ModeName
  isFreeUser: boolean
  chatId: string
}

interface PreambleProductTourProps {
  size: 'md' | 'lg'
  preamble: PreambleConfig
}

type PreambleProps = PreambleChatProps | PreambleProductTourProps

export const Preamble: FC<PreambleProps> = (props) => {
  const { mode, isFreeUser, size, preamble, chatId } = props as PreambleChatProps &
    PreambleProductTourProps

  const defaultPreamble = useMemo(() => {
    let titleIndex = 0
    if (chatId) {
      // Convert chatId to a number and use it to determine the title index
      const chatIdSum = chatId
        .split('-')
        .reduce((sum, part) => sum + parseInt(part, 16), 0)
      titleIndex = chatIdSum % defaultPreambleTitles.length
    }

    return {
      title: defaultPreambleTitles[titleIndex],
      description: isFreeUser
        ? 'Ask me anything about product, marketing, or growth, or try one of the actions below.'
        : "I'm your AI strategic advisor, here to help you leverage our expertise for your work."
    }
  }, [chatId, isFreeUser])

  const preambleModeMap: Record<ModeName, PreambleConfig> = {
    default: defaultPreamble,
    coaching: defaultPreamble,
    search: defaultPreamble,
    personalized_qa: {
      title: 'Tell me more about how I can help',
      description:
        "I'm here to be your thought partner and give you personalized advice to do your work better and faster. Ask a question, start a brainstorm, whatever you need!"
    },
    document_generation: defaultPreamble,
    suggest_course: {
      title: "Let's find a course just for you",
      description: ''
    },
    application: {
      title: "Hello there! I'm your Reforge application coach",
      description:
        'I am here to guide you through a rewarding journey of learning and growth'
    }
  }

  const resolvedPreamble =
    ('mode' in props ? preambleModeMap[mode] : preamble) || defaultPreamble

  return (
    <div className={cn(ASSISTANT_MESSAGE_BUBBLE_CLASSES, 'shrink-0 cursor-default')}>
      <div className="flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-rb-gray-400 mb-4">
        <img src={reforgeSymbol} className="h-[12px] w-[12px]" alt="" />
      </div>
      <div
        className={cn('font-polysans mb-3', {
          'text-[2rem] leading-[120%] -tracking-[0.64px]': size === 'md' || !size,
          'text-5xl leading-[120%]': size === 'lg'
        })}
      >
        {resolvedPreamble.title}
      </div>
      {resolvedPreamble.description && (
        <div className="break-words">{resolvedPreamble.description}</div>
      )}
    </div>
  )
}
