import { cn } from 'utils/tailwind'

import { USER_MESSAGE_BUBBLE_CLASSES } from '../Chat/helpers'
import { GlobalChatUIProps } from '../GlobalChatUI'
import { ButtonPrompt, SuggestedPromptsLoading } from './SuggestedPrompts'

type InChatSuggestedPromptsProps = Pick<
  GlobalChatUIProps,
  'isLoading' | 'sendMessage' | 'suggestedPrompts' | 'suggestedPromptsLoading'
> & {
  fullWidthProps?: boolean
}

export const InChatSuggestedPrompts = ({
  isLoading: loadingMessages,
  sendMessage,
  suggestedPrompts,
  suggestedPromptsLoading,
  fullWidthProps = false
}: InChatSuggestedPromptsProps) => {
  const className = cn(
    USER_MESSAGE_BUBBLE_CLASSES,
    'ml-0 cursor-pointer rounded-lg border border-rb-teal-50 bg-rb-teal-50 bg-opacity-40 transition duration-200 ease-in-out hover:bg-rb-teal-50 hover:opacity-100',
    fullWidthProps && 'w-full'
  )

  if (loadingMessages) return null

  return (
    <div className="flex flex-wrap gap-x-4 gap-y-2 overflow-hidden">
      {suggestedPromptsLoading ? (
        <SuggestedPromptsLoading />
      ) : (
        suggestedPrompts.map((prompt) => (
          <ButtonPrompt
            key={`${prompt}`}
            onClick={() => sendMessage(prompt, { isSuggestedFollowup: true })}
            className={className}
          >
            {prompt}
          </ButtonPrompt>
        ))
      )}
    </div>
  )
}
