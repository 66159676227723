import { useState } from 'react'

import { ErrorMessage } from 'components'
import Button from 'components/Button'
import { Modal, ModalContent, ModalFooter } from 'components/Modal'

import { SubscriptionReactivationFieldsFragment } from 'gql'

import useModalTracking from 'hooks/useModalTracking'

import { sendData } from 'utils/sendData'
import { trackCtaClicked, trackModalDismissed } from 'utils/tracking/analytics'

import { ReactivateSubscriptionPayload } from 'typings/payloads'

interface ReactivationModalProps {
  getLastFour: () => void
  isOpen: boolean
  setIsOpen: (arg0: boolean) => void
  setOpenConfirmationModal: (arg0: boolean) => void
  setShouldInitiateReactivationFlow: (arg0: boolean) => void
  subscription: SubscriptionReactivationFieldsFragment
}

export default function ReactivationModal({
  getLastFour,
  isOpen,
  setIsOpen,
  setOpenConfirmationModal,
  setShouldInitiateReactivationFlow,
  subscription
}: ReactivationModalProps) {
  const [showError, setShowError] = useState(false)

  useModalTracking(isOpen, {
    modal_group: 'reactivation_flow',
    modal_name: 'reactivation_confirmation'
  })

  const handleClose = () => {
    trackModalDismissed({
      category: 'app',
      modal_group: 'reactivation_flow',
      modal_name: 'reactivation_confirmation'
    })
    setShouldInitiateReactivationFlow(false)
    setIsOpen(false)
  }

  const reactivateSubscription = (subscriptionId: string, getLastFour: () => void) => {
    setShowError(false)
    const data: ReactivateSubscriptionPayload = {
      subscriptions: {
        revert_cancel: true
      }
    }
    sendData(`/api/v1/subscriptions/${subscriptionId}`, 'PATCH', data, (error: any) => {
      if (error) {
        setShowError(true)
      } else {
        setIsOpen(false)
        setOpenConfirmationModal(true)
      }
    })
    getLastFour && getLastFour()
  }

  const handleConfirm = (subscriptionId: string, getLastFour: () => void) => {
    trackCtaClicked({
      cta_location: 'reactivation_confirmation_modal',
      cta_type: 'button',
      text: 'confirm reactivation'
    })
    reactivateSubscription(subscriptionId, getLastFour)
  }

  const chargeText = (
    <>
      Once confirmed, your account will be charged on your next renewal date. For more
      information, visit <a href="/billing">Billing</a>.
    </>
  )

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className="max-w-screen-md"
      scrollContent={false}
    >
      <>
        <ModalContent className="mt-[28px]">
          <h1 className="mb-[16px] text-xl font-semibold leading-7">
            Restart Subscription
          </h1>
          <p className="text-md mb-0 font-normal" data-testid="charge-text-new">
            {chargeText}
          </p>
        </ModalContent>
        <ModalFooter>
          <Button onClick={handleClose} size="small" variant="text-only" className="mr-4">
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirm(subscription.id, getLastFour)}
            size="small"
            dataTest="charge-confirm-btn"
          >
            Confirm Restart
          </Button>
        </ModalFooter>

        {showError && (
          <ErrorMessage error="Sorry, something went wrong. Please try again soon or contact us at hello@reforge.com" />
        )}
      </>
    </Modal>
  )
}
