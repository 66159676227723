import { ApolloProvider } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { privateApolloClient, publicApolloClient } from 'apolloClient'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { GlobalChat } from 'domains/Ai/Chat'
import { GlobalChatProvider, isOnSidePanelChatPage } from 'domains/Ai/GlobalChatProvider'
import { GlobalChatTrackingProvider } from 'domains/Ai/GlobalChatTrackingProvider'
import { AddPostModalProvider } from 'domains/Post/AddPostModal'

import { Loading } from 'components'
import BugsnagProvider, { SetupBugsnagUser } from 'components/BugsnagProvider'
import DatadogProvider from 'components/DatadogProvider/DatadogProvider'
import { SetupDatadogUserSession } from 'components/DatadogProvider/SetupDatadogUserSession'
import { GlobalModalProvider } from 'components/GlobalModal'
import { SetupLaunchDarklyUser } from 'components/LaunchDarklyProvider/SetupLaunchDarklyUser'
import RefetchProvider from 'components/RefetchProvider'
import SetupFeatureFlagTracking from 'components/SetupFeatureFlagTracking'
import { SIDE_PANEL_BREAKPOINT } from 'components/SidePanel'
import RouterProvider from 'components/providers/RouterProvider'

// import { SetupSprig } from 'components/SetupSprig'
import { useUrlChangeMutation } from 'gql'

import { UserContextProvider } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useFlashMessage from 'hooks/useFlashMessage'
import useInterTabCommunication from 'hooks/useInterTabCommunication'
import { LocalStorageGlobalContextProvider } from 'hooks/useLocalStorageGlobal'
import useMediaQuery from 'hooks/useMediaQuery'

import 'utils/polyfill'
import { getAnonymousId, trackPage } from 'utils/tracking/segment'
import { isAuthPath } from 'utils/url'

import PageLoadContext from '../javascript/domains/Sanity/marketingSite/Contexts/PageLoadContext'

export const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY as string)

interface GlobalProvidersProps {
  isLoggedIn: boolean
  children: React.ReactNode[]
}

const GlobalProviders = ({ isLoggedIn, children }: GlobalProvidersProps) => {
  const location = useLocation()
  const { aiEmbeddedOnHomepage } = useFeatureFlags()

  const isAboveSidePanelMinWidth = useMediaQuery(
    `(min-width: ${SIDE_PANEL_BREAKPOINT}px)`
  )

  const useEmbeddedChat = useMemo(() => {
    const onChatEnabledPage = isOnSidePanelChatPage(location.pathname)
    return aiEmbeddedOnHomepage && onChatEnabledPage && isAboveSidePanelMinWidth
  }, [aiEmbeddedOnHomepage, location, isAboveSidePanelMinWidth])

  return (
    <RouterProvider>
      <BugsnagProvider>
        <DatadogProvider>
          <LocalStorageGlobalContextProvider>
            <InitialSetup isLoggedIn={isLoggedIn}>
              <ApolloProvider
                client={isLoggedIn ? privateApolloClient : publicApolloClient}
              >
                <UserContextProvider isLoggedIn={isLoggedIn}>
                  <Elements stripe={stripePromise}>
                    <SetupBugsnagUser />
                    <SetupDatadogUserSession />
                    {/* <SetupSprig isLoggedIn={isLoggedIn} /> */}
                    <RefetchProvider>
                      <SetupLaunchDarklyUser />
                      <SetupFeatureFlagTracking />
                      <GlobalChatTrackingProvider isLoggedIn={isLoggedIn}>
                        <GlobalChatProvider isLoggedIn={isLoggedIn}>
                          <GlobalModalProvider>
                            <AddPostModalProvider isLoggedIn={isLoggedIn}>
                              {children}
                            </AddPostModalProvider>
                            {!useEmbeddedChat && <GlobalChat renderStyle="draggable" />}
                          </GlobalModalProvider>
                        </GlobalChatProvider>
                      </GlobalChatTrackingProvider>
                    </RefetchProvider>
                  </Elements>
                </UserContextProvider>
              </ApolloProvider>
            </InitialSetup>
          </LocalStorageGlobalContextProvider>
        </DatadogProvider>
      </BugsnagProvider>
    </RouterProvider>
  )
}

interface InitialSetupProps {
  isLoggedIn: boolean
  children: React.ReactNode
}

const InitialSetup = ({ isLoggedIn, children }: InitialSetupProps) => {
  const { pathname } = useLocation()
  const [youtubeIframeScriptLoaded, setYoutubeIframeScriptLoaded] = useState(false)
  const isComponentMounted = useRef(false)
  const [urlChange] = useUrlChangeMutation({
    client: isLoggedIn ? privateApolloClient : publicApolloClient
  })

  useFlashMessage()
  useInterTabCommunication((message) => {
    if (message === 'reload') {
      window.location.reload()
    }
  })
  const manualPageLoadTriggerRef = useRef<boolean>(false)

  const setManualPageLoadTrigger = (value: boolean) => {
    manualPageLoadTriggerRef.current = value
  }

  // Function to trigger manual page load event
  const triggerPageLoadEvent = () => {
    // account for Helmet title change
    setTimeout(() => {
      trackPage()
    }, 0)
  }

  // Track page views in Segment
  useEffect(() => {
    async function sendUrlChange() {
      try {
        await urlChange({
          variables: {
            input: {
              fullUrl: window.location.href,
              referrer: document.referrer,
              search: new URLSearchParams(window.location.search).toString(),
              userAgent: window.navigator.userAgent,
              anonymousId: getAnonymousId()
            }
          }
        })
      } catch (e) {
        // error is handled in mutation response
      }
    }

    setTimeout(() => {
      if (!manualPageLoadTriggerRef.current) {
        triggerPageLoadEvent()
      }
    }, 500)

    // track page visit on server for SPA route change, skipping initial load
    if (!isAuthPath() && isComponentMounted.current) {
      sendUrlChange()
    }

    isComponentMounted.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, urlChange])

  // Loads Youtube Iframe API script which is used globally to render all Sanity embeded Youtube videos
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return
    }

    const script = document.createElement('script')
    script.src = 'https://www.youtube.com/iframe_api'
    script.id = 'youtube-iframe-api'

    document.body.appendChild(script)
  }, [])

  window.onYouTubeIframeAPIReady = () => {
    setYoutubeIframeScriptLoaded(true)
  }

  if (!isComponentMounted.current) return <Loading />

  return (
    <PageLoadContext.Provider
      value={{
        setManualPageLoadTrigger,
        triggerPageLoadEvent,
        youtubeIframeScriptLoaded
      }}
    >
      {children}
    </PageLoadContext.Provider>
  )
}

export default GlobalProviders
