import { useLottie } from 'lottie-react'
import { FC, useCallback, useEffect, useMemo } from 'react'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'

import RequestLoadingBar from 'components/RequestLoadingBar'
import logoLoadingAnimation from 'components/animations/logo-loading.json'
import { SkeletonContentCardContainer } from 'components/skeletons/cards'

import useContainerQuery from 'hooks/useContainerQuery'
import useURLParams from 'hooks/useURLParams'

import { cn } from 'utils/tailwind'

export const FIRST_TIME_USER_PARAM = 'first-time'

interface HomePageLoaderProps {
  requestCompleted: boolean
  onCompleted: () => void
}

export const HomePageLoader: FC<HomePageLoaderProps> = ({
  requestCompleted,
  onCompleted
}) => {
  const { getParam } = useURLParams()
  const firstTime = !!getParam(FIRST_TIME_USER_PARAM)
  const { isSidePanelChatOpenDelayed } = useGlobalChat()
  const [{ isMobileView, threeSlides, fourSlides }, loadingContainerRef] =
    useContainerQuery({
      isMobileView: {
        maxWidth: 770 // px
      },
      threeSlides: {
        minWidth: 848 // px
      },
      fourSlides: {
        minWidth: 1181 // px
      }
    })
  const { View } = useLottie({
    animationData: logoLoadingAnimation,
    loop: true,
    autoplay: true
  })

  const visibleCardsPerRow = useMemo(() => {
    if (isMobileView || isSidePanelChatOpenDelayed) return 4 // render a full sliders worth of skeletons even though they are not all visible
    if (!threeSlides) return 2
    if (!fourSlides) return 3

    return 4
  }, [isSidePanelChatOpenDelayed, isMobileView, threeSlides, fourSlides])

  const handleCompleted = useCallback(() => {
    if (firstTime) {
      const currentUrl = new URL(window.location.href)
      currentUrl.searchParams.delete(FIRST_TIME_USER_PARAM)
      const newUrl = currentUrl.href
      window.history.pushState({ path: newUrl }, '', newUrl)
    }
    onCompleted()
  }, [firstTime, onCompleted])

  // ensure loading animation stops for non-first-time users
  useEffect(() => {
    if (!firstTime && requestCompleted) {
      onCompleted()
    }
  }, [firstTime, requestCompleted, onCompleted])

  return firstTime ? (
    <div className="h-full flex-grow w-full flex flex-col items-center justify-center bg-rb-gray-20 rounded-xl">
      <div className="w-20 h-20 mb-6">{View}</div>
      <p className="font-semibold mb-6 text-center">
        Generating course and content recommendations for you...
      </p>
      <div className="w-full max-w-[12.5rem]">
        <RequestLoadingBar
          estimatedTimeToCompletion={2000}
          minimumTime={4000}
          requestCompleted={requestCompleted}
          onCompleted={handleCompleted}
        />
      </div>
    </div>
  ) : (
    <div ref={loadingContainerRef} className="overflow-hidden">
      {Array.from({ length: 4 }).map((_, i) => (
        <SkeletonContentCardContainer
          key={i}
          cardCount={visibleCardsPerRow}
          className={cn('mb-16 !mr-0', {
            '!grid-cols-[repeat(4,minmax(289px,1fr))]':
              visibleCardsPerRow === 4 && !(isMobileView || isSidePanelChatOpenDelayed),
            '!grid-cols-[repeat(3,minmax(289px,1fr))]': visibleCardsPerRow === 3,
            '!grid-cols-[repeat(2,minmax(289px,1fr))]': visibleCardsPerRow === 2,
            '!grid-cols-[repeat(4,289px)]': isMobileView || isSidePanelChatOpenDelayed
          })}
        />
      ))}
    </div>
  )
}
