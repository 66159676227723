import React, { Fragment, useLayoutEffect } from 'react'
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom'

import { Loading } from 'components'

import { GlobalChatUIProps, OnGeneratedDraftCtaClickProps } from '../GlobalChatUI'
import { InChatSuggestedPrompts } from '../SuggestedPrompts'
import { CopyableProvider } from '../hooks/useCopyable'
import { Feedback } from './Feedback'
import { MessageCard } from './MessageCard'
import { Preamble } from './Preamble'
import ScrollToBottomButton from './ScrollToBottomButton'
import { ScrollableChatContainer } from './ScrollableChatContainer'
import { useDelayMessageTimer } from './useDelayMessageTimer'

export type ChatFeedProps = Pick<
  GlobalChatUIProps,
  | 'chatId'
  | 'messages'
  | 'isLoading'
  | 'loadingSession'
  | 'reload'
  | 'mode'
  | 'suggestedPrompts'
  | 'suggestedPromptsLoading'
  | 'sendMessage'
> & {
  onSourceLinkClick: () => void
  draftTemplateName?: string
  onGeneratedDraftCtaClick: ({
    message,
    htmlString,
    title
  }: OnGeneratedDraftCtaClickProps) => void
  isFreeUser: boolean
}

const MessageCardMemoized = React.memo(MessageCard)

export const ChatFeed = ({
  chatId,
  messages,
  isLoading,
  loadingSession,
  reload,
  onSourceLinkClick,
  draftTemplateName,
  onGeneratedDraftCtaClick,
  suggestedPrompts,
  suggestedPromptsLoading,
  sendMessage,
  mode,
  isFreeUser
}: ChatFeedProps) => {
  return (
    <ScrollToBottom
      className="h-full grow"
      followButtonClassName="hidden"
      scrollViewClassName="hide-scrollbar"
      debounce={100}
    >
      <ChatFeedContent
        chatId={chatId}
        messages={messages}
        isLoading={isLoading}
        loadingSession={loadingSession}
        reload={reload}
        onSourceLinkClick={onSourceLinkClick}
        draftTemplateName={draftTemplateName}
        onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
        suggestedPrompts={suggestedPrompts}
        suggestedPromptsLoading={suggestedPromptsLoading}
        sendMessage={sendMessage}
        mode={mode}
        isFreeUser={isFreeUser}
      />
    </ScrollToBottom>
  )
}

export const ChatFeedContent = React.memo(function ChatFeedContent({
  chatId,
  messages,
  isLoading,
  loadingSession,
  reload,
  onSourceLinkClick,
  draftTemplateName,
  onGeneratedDraftCtaClick,
  suggestedPrompts,
  suggestedPromptsLoading,
  sendMessage,
  mode,
  isFreeUser
}: ChatFeedProps) {
  const scrollToBottom = useScrollToBottom()

  const lastMessage = messages[messages.length - 1]

  useLayoutEffect(() => {
    scrollToBottom({ behavior: 'smooth' })
  }, [scrollToBottom, lastMessage])

  const { showDelayMessage } = useDelayMessageTimer({ messages, isLoading })

  if (loadingSession) return <Loading />

  const showSuggestedPrompts =
    mode.mode === 'personalized_qa' || (mode.mode === 'default' && messages.length > 0)

  return (
    <>
      <ScrollToBottomButton />
      <ScrollableChatContainer>
        <Preamble mode={mode.mode} isFreeUser={isFreeUser} chatId={chatId} />
        {messages.map((message, index) => {
          const isLastMessage = index === messages.length - 1
          const shouldShowMessageActions =
            message.role === 'assistant' &&
            !message.isPredefined &&
            !message.aiDocument &&
            !isLoading

          return (
            <Fragment key={message.id}>
              <CopyableProvider>
                <MessageCardMemoized
                  key={message.id}
                  chatId={chatId}
                  message={message}
                  onSourceLinkClick={onSourceLinkClick}
                  onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
                  isLoading={isLoading}
                  isLast={isLastMessage}
                />
                {shouldShowMessageActions && (
                  <Feedback
                    mode={mode}
                    onReload={reload}
                    allowReload={isLastMessage && message.role === 'assistant'}
                    chatId={chatId}
                    message={message}
                    draftTemplateName={draftTemplateName}
                  />
                )}
              </CopyableProvider>
            </Fragment>
          )
        })}
        {showDelayMessage && (
          <div className="flex justify-center">
            <span>⏳ Response generation in progress. Thanks for your patience! ...</span>
          </div>
        )}
        {showSuggestedPrompts && (
          <div className="mt-auto">
            <InChatSuggestedPrompts
              isLoading={isLoading}
              sendMessage={sendMessage}
              suggestedPrompts={suggestedPrompts}
              suggestedPromptsLoading={suggestedPromptsLoading}
            />
          </div>
        )}
      </ScrollableChatContainer>
    </>
  )
})
