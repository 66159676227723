import { useEffect, useState } from 'react'

// source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_number_between_two_values
const getRandomChunkSize = (min = 4, max = 8) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const useStreamingText = () => {
  const [currentText, setCurrentText] = useState<string>('')
  const [isStreaming, setIsStreaming] = useState<boolean>(false)
  const [streamedText, setStreamedText] = useState<string>('')

  useEffect(() => {
    if (currentText) {
      setIsStreaming(true)
      setStreamedText('')
      let index = 0

      const interval = setInterval(() => {
        if (index < currentText.length) {
          const chunkSize = getRandomChunkSize()
          const nextChunk = currentText.slice(index, index + chunkSize)
          setStreamedText((prev) => prev + nextChunk)
          index += chunkSize
        } else {
          clearInterval(interval)
          setIsStreaming(false)
          setStreamedText('')
          setCurrentText('')
        }
      }, 40)

      return () => {
        clearInterval(interval)
        setIsStreaming(false)
        setStreamedText('')
        setCurrentText('')
      }
    }
  }, [currentText])

  return [streamedText, setCurrentText, isStreaming] as const
}
