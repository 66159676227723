import { FC, useEffect, useRef, useState } from 'react'

export interface RequestLoadingBarProps {
  estimatedTimeToCompletion: number // milliseconds
  requestCompleted: boolean
  onCompleted: () => void
  minimumTime?: number // milliseconds, optional
}

export const RequestLoadingBar: FC<RequestLoadingBarProps> = ({
  estimatedTimeToCompletion,
  requestCompleted,
  onCompleted,
  minimumTime = 0
}) => {
  const [progress, setProgress] = useState(0)
  const animationFrameRef = useRef<number | null>(null)

  const initialStartTimeRef = useRef<number>(performance.now())
  const startTimeRef = useRef<number>(performance.now())
  const startProgressRef = useRef<number>(0)
  const requestCompletedRef = useRef<boolean>(false)

  const totalDurationRef = useRef<number>(
    Math.max(estimatedTimeToCompletion, minimumTime)
  )
  const maxProgressBeforeCompletion = 95

  // returns a modified `t` such that the change in t is slower at the beginning
  // and end of the animation, reaching peak speed in the middle. `t` represents
  // the progress of the animation as a fraction between 0 and 1 (where 0 is the
  // start of the animation and 1 is the end). It returns an adjusted value based
  // on whether t is in the first half (t < 0.5) or the second half (t >= 0.5) of
  // the animation
  const easeInOut = (t: number) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
  }

  useEffect(() => {
    const animate = () => {
      const currentTime = performance.now()
      const elapsed = currentTime - startTimeRef.current
      const totalElapsed = currentTime - initialStartTimeRef.current

      const targetProgress = requestCompletedRef.current
        ? 100
        : maxProgressBeforeCompletion
      const duration = requestCompletedRef.current
        ? (() => {
            if (minimumTime > totalElapsed) {
              return minimumTime - totalElapsed
            } else {
              return 300 // 300ms to finish
            }
          })()
        : totalDurationRef.current

      const progressFraction = Math.min(elapsed / duration, 1)
      const easedProgress = easeInOut(progressFraction)
      const newProgress =
        startProgressRef.current +
        (targetProgress - startProgressRef.current) * easedProgress

      setProgress(newProgress)

      if (progressFraction < 1) {
        animationFrameRef.current = requestAnimationFrame(animate)
      } else {
        if (requestCompletedRef.current && newProgress >= 100) {
          setTimeout(() => {
            // add slight delay so user can see progress bar hit 100
            onCompleted()
          }, 150)
        } else if (!requestCompletedRef.current) {
          // Continue animating if request is not completed
          startTimeRef.current = performance.now()
          startProgressRef.current = newProgress
          animationFrameRef.current = requestAnimationFrame(animate)
        }
      }
    }

    animationFrameRef.current = requestAnimationFrame(animate)

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [minimumTime, onCompleted])

  useEffect(() => {
    if (requestCompleted && !requestCompletedRef.current) {
      requestCompletedRef.current = true
      startTimeRef.current = performance.now()
      startProgressRef.current = progress
    }
  }, [requestCompleted, progress])

  return (
    <div className="w-full h-2 bg-[#E4E6E3] rounded-full overflow-hidden">
      <div
        className="h-full bg-rb-blue rounded-full transition-all ease-in-out duration-100"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  )
}
