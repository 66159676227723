import { useCallback, useRef } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { ContentPercentComplete } from 'components/cards/Content/utils'

import {
  TrackCourseDashboardContentClickedCclCourseBlockPartsFragment,
  TrackCourseDashboardContentClickedCourseBlockPartsFragment,
  TrackCourseDashboardContentClickedCoursePartsFragment,
  TrackCourseDashboardContentClickedCourseSessionPartsFragment,
  useTrackServerEventMutation
} from 'gql'

import { CourseIdentifiersType } from 'utils/courseUtils'

import { getAnonymousId } from './tracking/segment'
import { LESSON_VIEWER_URL } from './url'

export const CONTENT_MODE_ON_DEMAND = 'on-demand'
export const CONTENT_MODE_ASYNC = 'async'
export const CONTENT_MODE_PREVIEW = 'preview'
export const CONTENT_MODE_PRE_SESSION = 'pre-session'
export const CONTENT_MODE_IN_SESSION = 'in-session'
export const CONTENT_MODE_POST_SESSION = 'post-session'

/* eslint-disable camelcase */
export interface CourseRelatedIdentifierProperties {
  course_id?: string | number
  ccl_course_id?: string | number
  ccl_local_id?: string | number | null
  content_mode: string | null
  course_session_id?: string | number | null
  ccl_course_session_id?: string | number | null
  ccl_course_static_id?: string | number | null
  course_title?: string
}
/* eslint-enable camelcase */

const COURSES_PATH = '/courses/'

export const useContentMode = (courseIdentifiers?: CourseIdentifiersType) => {
  const { pathname } = useLocation()

  const contentMode = contentModeFromPath(pathname)

  if (contentMode) {
    return contentMode
  }

  const courseSession = courseIdentifiers?.courseSession

  if (!courseSession) {
    return null
  }

  return contentModeFromCourseSession(courseSession)
}

export const contentModeFromCourseSession = (
  courseSession:
    | {
        hasEnded: boolean
        hasStarted: boolean
      }
    | null
    | undefined
) => {
  if (!courseSession) {
    return null
  }

  if (courseSession.hasEnded) {
    return CONTENT_MODE_POST_SESSION
  }

  if (courseSession.hasStarted) {
    return CONTENT_MODE_IN_SESSION
  }

  return CONTENT_MODE_PRE_SESSION
}

const contentModeFromPath = (pathname: string) => {
  if (!pathname.startsWith('/courses')) {
    return CONTENT_MODE_ASYNC
  }

  if (pathname.includes('/preview')) {
    return CONTENT_MODE_PREVIEW
  }

  if (pathname.includes('/on-demand')) {
    return CONTENT_MODE_ON_DEMAND
  }
}

export const isOnCoursePage = (pathname: string) => {
  const coursePage =
    pathname.startsWith(COURSES_PATH) &&
    pathname.split('/')[2] !== undefined &&
    !pathname.includes('/guides/') &&
    !pathname.includes('/events/') &&
    !pathname.includes('/artifacts/')
  const ignoredPaths = ['/courses/active']

  return coursePage && !ignoredPaths.includes(pathname)
}

export const isOnLessonPage = (pathname: string) => {
  return !!matchPath(pathname, { path: LESSON_VIEWER_URL })
}

export const isOnProgramPage = (pathname: string) => {
  const programPathParts = pathname.split('/programs/')
  return programPathParts[1] !== undefined && programPathParts[1] !== ''
}

export const isOnGuidePage = (pathname: string) => {
  const guidePathParts = pathname.split('/guides/')
  return guidePathParts[1] !== undefined && guidePathParts[1] !== ''
}

export const isOnArtifactPage = (pathname: string) => {
  const artifactPathParts = pathname.split('/artifacts/')
  return artifactPathParts[1] !== undefined && artifactPathParts[1] !== ''
}

type ContentType = 'artifact' | 'guide' | 'course' | 'lesson' | 'program' | undefined
export const contentTypeFromPathname: (pathname: string) => ContentType = (pathname) => {
  if (isOnProgramPage(pathname)) return 'program'
  if (isOnLessonPage(pathname)) return 'lesson'
  if (isOnCoursePage(pathname)) return 'course'
  if (isOnArtifactPage(pathname)) return 'artifact'
  if (isOnGuidePage(pathname)) return 'guide'
}

export type CoursePageUrlParts = {
  courseSlug: string
  courseSessionId?: string | null
  contentMode?: string | null
}

export const parseCoursePageUrl = (pathname: string): CoursePageUrlParts | undefined => {
  if (isOnCoursePage(pathname)) {
    const parts = pathname.substring(COURSES_PATH.length).split('/')
    const courseSlug = parts[0]
    const courseSessionId = parts[2]

    return {
      courseSlug,
      courseSessionId,
      contentMode: contentModeFromPath(pathname)
    }
  }
}

export const useTrackCourseDashboardContentClicked = () => {
  const [trackServerEvent] = useTrackServerEventMutation()
  const { pathname } = useLocation()

  return ({
    contentMode,
    contentType,
    contentTitle,
    contentSanityId,
    contentCclLocalId,
    contentIndex = 0,
    course,
    courseSession,
    courseBlock,
    courseBlockIndex = 0,
    courseRelatedIdentifiers,
    destination
  }: {
    courseRelatedIdentifiers?: CourseRelatedIdentifierProperties
    contentMode: string
    contentType: string
    contentTitle: string
    contentSanityId?: string
    contentCclLocalId?: string | number
    contentIndex?: number
    course?: TrackCourseDashboardContentClickedCoursePartsFragment
    courseSession?: TrackCourseDashboardContentClickedCourseSessionPartsFragment | null
    courseBlock:
      | TrackCourseDashboardContentClickedCourseBlockPartsFragment
      | TrackCourseDashboardContentClickedCclCourseBlockPartsFragment
    courseBlockIndex?: number
    destination: string
  }) => {
    const relatedIdentifiersForCourse = courseRelatedIdentifiers || {
      course_id: course?.id,
      course_title: course?.title,
      course_session_id: courseSession?.id,
      sanity_course_id: course?.sanityId
    }

    trackServerEvent({
      variables: {
        input: {
          event: 'Content Clicked - Server',
          anonymousId: getAnonymousId(),
          properties: {
            content_type: contentType,
            content_title: contentTitle,
            content_sanity_id: contentSanityId,
            display_type: 'thumbnail',
            location: 'course_dashboard',
            content_ccl_local_id: contentCclLocalId,
            referrer: pathname,
            destination,
            relatedIdentifiers: {
              ...relatedIdentifiersForCourse,
              course_block_id: courseBlock.id,
              course_block_title: courseBlock.title,
              course_block_number: courseBlockIndex + 1,
              sanity_content_index: contentIndex + 1,
              content_mode: contentMode
            }
          }
        }
      }
    })
  }
}

/* eslint-disable camelcase */
interface trackContentTrackingMetadataBase {
  // consumption_format should be one of: audio, text, video
  consumption_format: string
  // content_mode should be one of the following, but typing elsewhere isn't as precise:
  // async, in-session, on-demand, post-session, pre-session, preview
  content_mode: string
  // content_type should be one of the following, but typing elsewhere isn't as precise:
  // artifact, course, event, flex_content, guide, lesson, module, program, section
  content_type: string
}

export interface trackVideoTrackingMetadata extends trackContentTrackingMetadataBase {
  content_id?: string
  content_ccl_local_id?: string
  content_name?: string
  related_identifiers?: {
    ccl_course_local_id?: string | number
    ccl_course_title?: string
    ccl_course_session_id?: string | number | null
  }
}
/* eslint-enable camelcase */

// TODO: Union other types in as they are defined
export type trackContentTrackingMetadata = trackVideoTrackingMetadata

interface handleContentProgressChangeParams {
  percentage?: number
  pressedPlay?: boolean
  trackingMetadata: trackContentTrackingMetadata
}

interface trackContentProgressParams {
  progressPercentage: ContentPercentComplete
  trackingMetadata: trackContentTrackingMetadata
}

export const useTrackContentProgressRecorded = () => {
  const { pathname } = useLocation()
  const [trackServerEvent] = useTrackServerEventMutation()

  const trackContentProgress = useCallback(
    ({ progressPercentage, trackingMetadata }: trackContentProgressParams) => {
      if (trackingMetadata) {
        trackServerEvent({
          variables: {
            input: {
              anonymousId: getAnonymousId(),
              event: 'Content Progress Recorded - Server',
              properties: {
                ...trackingMetadata,
                path: pathname,
                progress_percentage: progressPercentage / 100
              }
            }
          }
        })
      }
    },
    [trackServerEvent, pathname]
  )

  return { trackContentProgress }
}

export const useHandleContentProgressChange = () => {
  const { trackContentProgress } = useTrackContentProgressRecorded()
  const zeroPercentContentTracked = useRef(false)
  const firstQuartileContentTracked = useRef(false)
  const secondQuartileContentTracked = useRef(false)
  const thirdQuartileContentTracked = useRef(false)
  const ninetyPercentContentTracked = useRef(false)
  const oneHundredPercentContentTracked = useRef(false)

  const handleContentProgressChange = useCallback(
    ({
      pressedPlay,
      percentage,
      trackingMetadata
    }: handleContentProgressChangeParams) => {
      // The spec for pressing play is to log 0% progress, but
      // this doesn't really stand up to clicking pause and play
      // multiple times. Also, I noticed that the event that fires
      // onPlay has a "current time" marginally greater than 0, so
      // calculating exact progress and looking for 0 isn't going
      // to work, either. For now, we're going to log the first time
      // that the user presses "play" as 0%, and not thereafter.
      if (pressedPlay && !zeroPercentContentTracked.current) {
        zeroPercentContentTracked.current = true
        trackContentProgress({
          progressPercentage: ContentPercentComplete.ZeroPercent,
          trackingMetadata
        })
      } else if (percentage && percentage > 0) {
        if (
          !firstQuartileContentTracked.current &&
          percentage >= ContentPercentComplete.FirstQuartile
        ) {
          firstQuartileContentTracked.current = true
          trackContentProgress({
            progressPercentage: ContentPercentComplete.FirstQuartile,
            trackingMetadata
          })
        }

        if (
          !secondQuartileContentTracked.current &&
          percentage >= ContentPercentComplete.SecondQuartile
        ) {
          secondQuartileContentTracked.current = true
          trackContentProgress({
            progressPercentage: ContentPercentComplete.SecondQuartile,
            trackingMetadata
          })
        }

        if (
          !thirdQuartileContentTracked.current &&
          percentage >= ContentPercentComplete.ThirdQuartile
        ) {
          thirdQuartileContentTracked.current = true
          trackContentProgress({
            progressPercentage: ContentPercentComplete.ThirdQuartile,
            trackingMetadata
          })
        }

        if (
          !ninetyPercentContentTracked.current &&
          percentage >= ContentPercentComplete.NinetyPercent
        ) {
          ninetyPercentContentTracked.current = true
          trackContentProgress({
            progressPercentage: ContentPercentComplete.NinetyPercent,
            trackingMetadata
          })
        }

        if (
          !oneHundredPercentContentTracked.current &&
          percentage === ContentPercentComplete.OneHundredPercent
        ) {
          oneHundredPercentContentTracked.current = true
          trackContentProgress({
            progressPercentage: ContentPercentComplete.OneHundredPercent,
            trackingMetadata
          })
        }
      }
    },
    [trackContentProgress]
  )

  return handleContentProgressChange
}
